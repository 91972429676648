import React, { useContext } from 'react';
import Button from '../ui/Button';
import { Link } from 'gatsby';
import { MobileScreenContext } from '../Page';

export default function ROISection({data}){
  const {heading, description, returns} = data;
  const { mobileScreen } = useContext(MobileScreenContext);

  function displaySectionHeading(){
    return heading.map(headingText => (
      <h3 className='
        text-mobile-heading-lg laptop:text-[2rem] 
        font-bold
      '>
        {headingText}
      </h3>
    ))
  }

  function displayReturns(arr){ 
    return arr.map(returnsData => {
      const {highlightedText, text} = returnsData;

      return (
        <div className='mb-7'>
          <div className='text-sky-400 mb-2'>
            <h3 className='
              text-mobile-heading-xl laptop:text-[2.5rem] 
              font-bold 
              laptop:leading-[3.5rem]
            '>
              {highlightedText[0]}
            </h3>
            <p className='laptop:text-xl'>
              {highlightedText[1]}
            </p>
          </div>
          <p>
            {text}
          </p>
        </div>
      )
    })
  }

  if(!mobileScreen){
    return (
      <section 
        className='bg-blue-800 py-20 text-white'
        style={{borderRadius: '1rem 1rem 0 0'}}
      >
        <div className='grid grid-cols-2 gap-36 max-w-3/4 mx-auto'>
          <div className='flex flex-col justify-between h-[92%]'>
            <div>
              {displaySectionHeading()}
              <p className='mt-7 max-w-10/12'>{description[0]}</p>
            </div>
            <div>
              <p>{description[1]}</p>
              <div className='w-56 mt-7'>
                <Link to='/contact-us'> 
                  <Button type='light'> Get in touch now </Button>
                </Link>
              </div>
            </div>
          </div>
          <div>
            {displayReturns(returns)}
          </div>
        </div>
      </section>
    )
  }
  else{
      return (
        <section 
          className='
          bg-blue-800 text-white 
          flex flex-col 
          pt-10 pb-12 px-3 
          text-[0.75rem] 
          mobile-tablet-one:my-0 
          mx-auto 
          w-full mobile-tablet-one:max-w-11/12
        ' 
          style={{borderRadius: '1rem 1rem 0 0'}}
        >
          <div className='
            max-w-ful mobile-lg-tablet:max-w-11/12 mobile-tablet-one:max-w-[85%] mobile-tablet-two:max-w-[80%] 
            mx-auto
          '>
            {displaySectionHeading()}
            <p className='mt-4'>{description[0]}</p>
            <div className='grid grid-cols-2 gap-4 mt-7'>
              {displayReturns(returns.slice(0, 2))}
            </div>
            {displayReturns(returns.slice(2))}
            <p className='mb-4 mt-7'>{description[1]}</p>
            <Link to='/contact-us'>
              <Button type='light'> Get Started Now </Button>
            </Link>
          </div>
        </section>
      )
  }
}