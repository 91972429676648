import React from 'react';
import Page from '../components/Page';
import SeeItInAction from '../components/SeeItInAction';
import PartnersSection from '../components/Onboarding/PartnersSection';
import SupportAndSecuritySection from '../components/Onboarding/SupportAndSecuritySection';
import ROISection from '../components/Onboarding/ROISection';
import HeroSection from '../components/Onboarding/HeroSection';

const data = {
    heroSection: {
        heading: ['Launch in', '3 easy steps:'], 
        description: "Learn how you can install and start using Loopr's AI automation solutions on your factory floor.", 
        contentImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/onboarding-hero-content.png', 
        contentImgDimensions: {
            width: '90rem', 
            height: '47.18rem'
        },
        contentMobileImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/onboarding-hero-content-mobile.png',
        contentMobileImgDimensions: {
            width: '22.43rem', 
            height: '31.31rem'
        }
    }, 
    supportAndSecuritySection: [
        {
            heading: 'Future Support', 
            content: [
                {
                    heading: 'Future deployments', 
                    text: 'Future deployments are simple. We ship more hardware units pre-loaded with our application, so you can scale to more lines or factories within days', 
                    labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/nested-boxes-icon.png', 
                    labelImgDimensions: {
                        minWidth: '3.06rem', 
                        height: '3.06rem'
                    }
                }, 
                {
                    heading: 'Maintenance', 
                    text: 'Every solution includes software support and hardware maintenance, ensuring continuous operation of your application with high accuracy', 
                    labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/maintenance-icon.png',
                    labelImgDimensions: {
                        minWidth: '4.37rem', 
                        height: '4.06rem'
                    }
                }
            ], 
            imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/onboarding-content-01.png', 
            imgDimensions: {
                minWidth: '30.5rem', 
                height: '23.06rem'
            },
            flexDirection: 'row'
        }, 
        {
            heading: 'Security', 
            content: [
                {
                    heading: 'Data safety', 
                    text: 'Our solutions are built to work in your private cloud or premises, so that your data never leaves your network', 
                    labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/cloud-security-icon.png', 
                    labelImgDimensions: {
                        minWidth: '4.43rem', 
                        height: '2.93rem'
                    }
                }, 
                {
                    heading: 'Industry standard', 
                    text: 'Our own cloud infrastructure uses industry standard certifications and latest Azure security services to ensure your data is secure should you chose to deploy in our cloud tenant.', 
                    labelImgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/document-network-icon.png', 
                    labelImgDimensions: {
                        minWidth: '3.56rem', 
                        height: '3.31rem'
                    }
                }
            ], 
            imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/onboarding-content-02.png', 
            imgDimensions: {
                minWidth: '30.5rem', 
                height: '23.06rem'
            },
            flexDirection: 'row-reverse'
        }, 
    ], 
    roiSection: {
        heading: ['ROI', 'Examples:'], 
        description: [
            "Every customer's use case and how they derive value is unique to their processes, but here are some examples of how customers generate ROI on our solutions:", 
            'Schedule a call with our team today and see how Loopr can work for your organisation:'
        ], 
        returns: [
            {
                highlightedText: ['$50,000', 'savings each month'], 
                text: 'by avoiding re-work, using AI software to detect structural issues in raw material before processing'
            },
            {
                highlightedText: ['100%', 'reduction of QA cost'], 
                text: 'by replacing two manual inspectors with one manual inspector using AI assisted defect detection software '
            },
            {
                highlightedText: ['500', 'man-hour savings each month'], 
                text: 'by replacing manual data entry with automated Part & Label identification software'
            },
        ]
    }
}

export default function OnboardingPage(){
    const {heroSection, supportAndSecuritySection, roiSection} = data;

    return (
        <Page navbarButtonStyle='primary' selectedPage='Onboarding'>
            <HeroSection data={heroSection} />
            <PartnersSection />
            <SupportAndSecuritySection data={supportAndSecuritySection} />
            <ROISection data={roiSection} />
        </Page>
    )
}