import React from 'react';
import Button from './ui/Button';
import { Link } from 'gatsby';

export default function SeeItInAction(){
  return (
    <section className='
      bg-transparent 
      flex 
      mx-auto 
      pt-7 pb-10 
      laptop:py-20 
      max-w-11/12 laptop:max-w-10/12 large:max-w-2/3 
      laptop-sm-three:mt-5 laptop:mt-0
    '>
      <div className='
        flex 
        flex-col laptop-sm-three:flex-row 
        justify-center 
        laptop-sm-three:gap-x-7 laptop:gap-x-0 
        w-full
      '>
        <h2 className='
          font-medium 
          mobile-lg-tablet:w-[27.5rem] laptop-sm-three:w-[12rem] laptop:w-[16.43rem] 
          mobile-lg-tablet:mx-auto 
          laptop-sm-three:-mr-5 laptop-small:-mr-7 laptop:mr-4 
          laptop:ml-0 
          laptop-sm-three:text-[1.25rem] laptop:text-[2rem] 
          laptop-sm-three:pt-4 laptop:pt-0
        '>
          See it in action:
        </h2>
        <img 
          src='https://looprdevstorage.blob.core.windows.net/website-temp/see-it-in-action.png' 
          className='
            rounded-lg 
            my-2 mobile-lg-tablet:my-4 laptop:my-0 
            laptop:mr-10 
            mobile-lg-tablet:w-[27.5rem] laptop:w-[30.5rem] 
            mobile-tablet-one:h-[15rem] laptop-sm-three:h-[12rem] laptop:h-[15rem] 
            mx-auto
        '/>
        <div className='
          flex flex-col 
          mobile-lg-tablet:w-[27.5rem] laptop-sm-three:w-[20rem] laptop:w-96 
          text-mobile-base laptop-sm-three:text-[0.8rem] laptop:text-base 
          mx-auto 
          laptop-sm-three:pt-4 laptop:pt-0
        '>
          <p className='laptop:pr-7'>
            Learn how Apollo Endosurgery uses Loopr Defect Detection to automate quality inspection of its micron scale, 
            high precision manufactured parts.
          </p>
          <div className='
            w-full laptop-sm-three:w-48 laptop:w-56 
            mt-5 laptop-sm-three:mt-[5.9rem]
          '>
            <Button type='primary'>
              <Link to='/industries/medical-devices'>
                View case study
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}