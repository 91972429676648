import React, { useContext } from 'react'; 
import TopRightWavy from '../ui/TopRightWavy';
import { MobileScreenContext } from '../Page';
import Button from '../ui/Button';
import { Link } from 'gatsby';

export default function HeroSection({data}){
    const {heading, description, contentImgUrl, contentImgDimensions, contentMobileImgUrl, contentMobileImgDimensions} = data;
    const { mobileScreen, screenWidth } = useContext(MobileScreenContext);

    return (
        <section className='py-10 mobile-tablet-one:pb-5 laptop:pb-10'>
            <div>
                <div className='max-w-11/12 mobile-lg-tablet:max-w-[89%] mobile-tablet-one:max-w-[67%] mobile-tablet-two:max-w-[59%] laptop:max-w-10/12 large:max-w-3/4 mx-auto mb-3 laptop:mb-10'>
                    <h1 className='leading-tight text-mobile-heading-xl laptop:text-5xl font-bold laptop:font-medium'>{heading[0]} <span className='block laptop:inline text-blue-800'>{heading[1]}</span></h1>
                    <p className='mt-7 mb-4 laptop:my-10 laptop:w-5/12 text-mobile-base laptop:text-base'>{description}</p>
                    {mobileScreen && 
                        <Link to='/contact-us'>
                            <Button type='light'>Get Started Now</Button>
                        </Link>
                    }
                    <h2 className='text-mobile-heading-lg laptop:text-[2rem] font-medium mt-10 mobile-tablet-one:mb-4 laptop:mt-0'>6 week deployment:</h2>
                </div>
                <div className='flex justify-center'>
                    <img 
                        src={mobileScreen && screenWidth < 585 ? contentMobileImgUrl : contentImgUrl} 
                        alt='hero_section_content' 
                        className={`h-[30rem] mobile-lg-tablet:h-[34rem] laptop:h-[47rem]`}
                        // style={mobileScreen ? contentMobileImgDimensions: contentImgDimensions}
                    />
                </div>
            </div>
            <TopRightWavy position='-top-8 -right-0'/>
        </section>
    )
}