import React, { useContext } from 'react';
import { MobileScreenContext } from './Page';

export default function BannerSection({ children, classes = ''}){
  return (
            
    <div className={`
      px-2 mobile-lg-tablet-one:px-4 laptop:px-8 
      flex flex-wrap 
      gap-5 
      justify-evenly items-center 
      ${classes}
    `}>
      {children}
    </div>
  )
}