import React, { useContext } from 'react'; 
import Content from '../ui/Content';
import ContentSection from '../ui/ContentSection';
import BottomLeftWavy from '../ui/BottomLeftWavy';
import { MobileScreenContext } from '../Page';

export default function SupportAndSecuritySection({data}){
    const mobileScreen = useContext(MobileScreenContext)

    return (
        <div className='relative overflow-hidden'>
            <div>
                {data.map(sectionData => {
                    return <ContentSection data={sectionData} />
                })}
            </div>
            {!mobileScreen && <BottomLeftWavy />}
        </div>
       
    )
}