import React from 'react';
import BannerSection from '../BannerSection';

export default function PartnersSection(){
    return (
        <section className='mb-7 max-w-full mobile-lg-tablet:max-w-[89%] mobile-tablet-one:max-w-[70%] mobile-tablet-two:max-w-[60%] laptop:max-w-full mx-auto'>
            <div className='flex flex-col laptop:flex-row justify-center max-w-11/12 laptop:max-w-3/4 mx-auto'>
                <h3 className='text-mobile-heading-lg laptop:text-[2rem] font-medium laptop:mr-10'>Partners: </h3>
                <p className='text-[0.75rem] laptop:text-base laptop:max-w-2/3 pt-2'>
                    For highly tailored and large-scale deployments, we work with a partner network which offers AI consulting, system integration, and hardware solutions, 
                    enabling customers to successfully implement and uniquely realize the value of Loopr's AI automation solutions
                </p>
            </div>
            <div className='grid grid-cols-3 laptop:grid-cols-6 items-center justify-center laptop:gap-7 laptop:max-w-[90%] mx-auto py-5 laptop:py-10'>
            <img src='https://looprdevstorage.blob.core.windows.net/website-temp/microsoft-logo.png' style={{width: '11.43rem', height: '2.93rem'}} className='scale-x-75 scale-y-[55%] laptop:scale-100'/>
                <img src='https://looprdevstorage.blob.core.windows.net/website-temp/nvidia-logo.png' style={{width: '13.18rem', height: '2.68rem'}} className='laptop:ml-5 scale-x-75 scale-y-[55%] laptop:scale-100'/>
                <img src='https://looprdevstorage.blob.core.windows.net/website-temp/pwc-logo.png' style={{width: '5.87rem', height: '3.31rem'}} className='laptop:ml-14 scale-[60%] laptop:scale-100' />
                <img src='https://looprdevstorage.blob.core.windows.net/website-temp/slalom-logo.png' style={{width: '7.18rem', height: '1.93rem'}} className='laptop:ml-5 scale-[60%] laptop:scale-100'/>
                <img src='https://looprdevstorage.blob.core.windows.net/website-temp/sony-logo.png' style={{width: '8.62rem', height: '1.87rem'}} className='scale-[60%] laptop:scale-100'/>
                <img src='https://looprdevstorage.blob.core.windows.net/website-temp/hitachi-solutions-logo.png' style={{width: '8.68rem', height: '3rem'}}  className='scale-[60%] laptop:scale-100'/>
            </div>  
        </section>
    )
}